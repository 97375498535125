export default {
    //Variables generales
    token: localStorage.getItem("token" || '""'),
    userInfo: JSON.parse(localStorage.getItem("userInfo") || '""'),
    sidebar: localStorage.getItem("sidebar") || "",
    showSuccess: "",
    showError: "",
    mode: "",

    // Editar y ver usuario
    editUser: "",

    //Editar y ver perfil
    editProfile: "",

    //Asignar y reasignar lotes
    usersCreators: "",
    usersCreatorsSelected: [],
    combinations: [], // Usadas en el loop de tarjetas
    lotsCombination: {}, // Las que se traen en el backend
    totalLotsCount: 0,
    emptyLots: false,

    //Creación de Lotes
    creationDate: "",
    operationDays: "",
    combinationList: [],
    isLoading: true,
    isLoadingTable: true,
    dataNotFound: false,
    dataPickerDisabled: true,
    tableData: [],
};
