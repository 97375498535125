import axios from "../axios-config";

class AuthService {
    login(user){
        return axios.post('login', {
            user: user.user,
            password: user.password,
            device_name: 'Windows PC'
        });
    }
    logout(){
        return axios.get('logout');
    }
    resetPassword(user){
        return axios.post('/resetPassword', {
            token: user.token,
            password: user.password,
            password_confirmation: user.password_confirmation
        })
    }
    recoverPassword(userName){
        return axios.post('/recoverPassword', {
            login: userName,
        })
    }
    checkToken(params){
        return axios.get('/checkToken', {params}) //token
    }
}

const authService = new AuthService();

export default authService;