import axios from "../axios-config";

class TicketService {
  getAllTickets(value, params) {
    return axios.post("tickets/all", value, { params });
  }
  createTicket(data) {
    return axios.post("ticket/create", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  getUsersPending() {
    return axios.get("/ticket/allPending");
  }
  getPendingByAnalist(value, params) {
    return axios.post("/ticket/allPendingByAnalist",value, { params }
    );
  }
  getUserPending(id) {
    return axios.get("/ticket/userPending/" + id);
  }
  getUnassignedTickets() {
    return axios.get("ticket/unassigned");
  }
  assignTickets(userId, data) {
    return axios.post("ticket/assign/" + userId,{
      tickets: data,
    });
  }
  getContractsBySeller(sellerId, params) {
    return axios.get("ticket/contracts/" + sellerId, {params});
  }
  getAllContracts(params, filters) {
    return axios.get("ticket/allContracts", filters,{params});
  }

  getTicketDetail(id){
    return axios.get("ticket/show/" + id)
  }
  reassignAnalist(id, params){
    return axios.put("/ticket/reassign/" + id, params);
  }

  reassignTickets(params){
    //oldID,newID,amount

    return axios.put("/ticket/reassignMultiple", params);
  }
  editContractVisaFromTicket(id, params){
    return axios.put(`/contract/${id}/visa/editContractVisaFromTicket`, params)
  }
  closeTicket(id){
    return axios.get("/ticket/close/" + id)
  }
  getStatuses(){
    return axios.get("/ticket/statuses")
  }
  addMessage(ticketId, params) {
    return axios.post("/ticket/addMessage/" + ticketId, params);
  }
  getAttachedFile(id){
    return axios.get("ticket/download/" + id, {responseType: 'blob'}) 
  }
  // getContractFilterStatus(id){
  //   return axios.get("")
  // }
}

const ticketService = new TicketService();
export default ticketService;
