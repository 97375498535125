const breadcrumb = {
  informes: { text: "Informes", href: "informes", color: "" },
  visadoDeLotes: { text: "Visado de Lotes", href: "visadodelotes", color: "" },
  visadoPozo: { text: "Visado Pozo", href: "visadopozo", color: "" },
  reportes: { text: "Reportes", href: "reportes", color: "" },
  historial: { text: "Contratos", href: "historial", color: "" },
};

const routes = [
  {
    path: "/reporteslotes",
    name: "reporteslotes",
    component: () => import("@/views/Reports/LotsReportsView.vue"),
    meta: {
      breadcrumb: [breadcrumb.informes, breadcrumb.visadoDeLotes],
      readOnly: true,
      requiredPermissions: ["ver_reportes"],
    },
  },
  {
    path: "/reportespozo",
    name: "reportespozo",
    component: () => import("@/views/Reports/PozoReportsView.vue"),
    meta: {
      breadcrumb: [breadcrumb.informes, breadcrumb.visadoPozo],
      readOnly: true,
      requiredPermissions: ["ver_reportes"],
    },
  },
  {
    path: "/reportesgeneral",
    name: "reportesgeneral",
    component: () => import("@/views/Reports/VTRGeneralReportsView.vue"),
    meta: {
      breadcrumb: [breadcrumb.reportes],
      readOnly: true,
      requiredPermissions: ["subir_reportes"],
    },
  },
  {
    path: "/reportesventasfijas",
    name: "reportesventasfijas",
    component: () => import("@/views/Reports/VTRFixedSalesReportsView.vue"),
    meta: {
      breadcrumb: [breadcrumb.reportes],
      readOnly: true,
      requiredPermissions: ["subir_reportes"],
    },
  },
  {
    path: "/reportesventasmoviles",
    name: "reportesventasmoviles",
    component: () => import("@/views/Reports/VTRMobileSalesReportsView.vue"),
    meta: {
      breadcrumb: [breadcrumb.reportes],
      readOnly: true,
      requiredPermissions: ["subir_reportes"],
    },
  },
  {
    path: "/reportesprefactura",
    name: "reportesprefactura",
    component: () => import("@/views/Reports/VTRSalesReportInvoice.vue"),
    meta: {
      breadcrumb: [breadcrumb.reportes],
      readOnly: true,
      requiredPermissions: ["subir_reportes"],
    },
  },
  {
    path: "/historialcontratos",
    name: "historialcontratos",
    component: () => import("@/views/ContractHistory/ContractHistoryView.vue"),
    meta: {
      breadcrumb: [breadcrumb.informes, breadcrumb.historial],
      readOnly: true,
      transparentCard: true,
      breadcrumbSeparate: true,
      requiredPermissions: ["ver_historial"],
    },
  },
  
  {
    path: "/historialcontratos/:contractId/lote/editar",
    name: "editarvisadolote",
    component: () => import("@/views/Visa/VisadoView.vue"),
    meta: {
      breadcrumb: [breadcrumb.visados],
      readOnly: true,
      showEditForm: true,
      requiredPermissions: ["cambiar_resultado_contrato"],
    },
  },
  {
    path: "/historialcontratos/:id/pozo/editar",
    name: "editarvisadopozo",
    component: () => import("@/views/VisaPozo/PozoVisadoView.vue"),
    meta: {
      breadcrumb: [breadcrumb.visadosPozo],
      requiredPermissions: ["cambiar_resultado_contrato"],
    }
  },



];

export default routes;
