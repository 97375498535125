import pozoService from "@/services/TicketService";
export default {
    state: () => ({
      showComponentReassign:false,
    }),
    mutations: {
      setshowReassign(state, value) {
        state.showComponentReassign = value;
      },
    },
    getters: {},
    actions: {
    },
  };
  