const breadcrumb = {
	usuario: { text: "Usuarios", to: { name: "usuarios" }, color: "" },
	crearUsuario: { text: "Crear usuario", to: { name: "crearusuario" }, color: "" },
	editarUsuario: { text: "Editar usuario", to: { name: "editarusuario" }, color: "" },
	verUsuario: { text: "Ver usuario", to: { name: "verusuario" }, color: "" },
	perfil: { text: "Ver perfil", to: { name: "perfil" }, color: "" },
};

const routes = [
	{
		path: "/usuarios",
		name: "usuarios",
		component: () => import("@/views/User/AllUsersView.vue"),
		meta: {
			showSidebarUsers: true,
			showSuccessCreated: false,
			transparentCard: true,
			breadcrumbSeparate: true,
			breadcrumb: [breadcrumb.usuario],
			requiredPermissions: ["ver_usuarios"]
		},
	},
	{
		path: "/usuario/crear",
		name: "crearusuario",
		component: () => import("@/views/User/CreateUserView.vue"),
		meta: {
			breadcrumb: [breadcrumb.usuario, breadcrumb.crearUsuario],
			requiredPermissions: ["crear_usuario"]
		},
	},
	{
		path: "/usuario/:id/editar",
		name: "editarusuario",
		component: () => import("@/views/User/CreateUserView.vue"),
		meta: {
			breadcrumb: [breadcrumb.usuario, breadcrumb.editarUsuario],
			requiredPermissions: ["editar_usuario"]
		},
	},
	{
		path: "/usuario/:id/ver",
		name: "verusuario",
		component: () => import("@/views/User/CreateUserView.vue"),
		meta: {
			breadcrumb: [breadcrumb.usuario, breadcrumb.verUsuario],
			requiredPermissions: ["ver_usuarios"]
		},
	},
	{
		path: "/perfil",
		name: "perfil",
		component: () => import("@/views/User/CreateUserView.vue"),
		meta: {
			breadcrumb: [breadcrumb.perfil],
		},
	},
];
export default routes;
