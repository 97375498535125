import axios from "../axios-config";

class UserService {
    getUserInfo() {
        return axios.get("user/info");
    }

    getAllUsers(params, filters) {
        return axios.post("user/all", filters, { params });
    }
    filterUsers(profilesId, status) {
        const params = {
            orderStatus: "desc",
        };
        if (status) {
            status.forEach((state) => {
                Object.assign(params, { filterByStatus: state });
            });
            return axios.post("users/profile", profilesId, { params });
        }
        return axios.post("users/profile", profilesId, { params });
    }
    createNewUser(data) {
        return axios.post("register", data);
    }
    editUser(data) {
        return axios.put("user/update/" + data.id, data);
    }
    desactivateUser(id) {
        return axios.delete("user/delete/" + id);
    }
    activateUser(id) {
        return axios.post("user/restore/" + id);
    }
    isRutUserUnique(rut, idUser) {
        const params = {
            rut: rut,
            idUser: idUser,
        };
        return axios.get("user/rutExists", { params });
    }
    isLoginUnique(login, idUser) {
        const params = {
            login: login,
            idUser: idUser,
        };
        return axios.get("user/loginExists", { params });
    }
    getUserById(id) {
        return axios.get("user/get/" + id);
    }
    getMonitors() {
        return axios.get("profile/monitors");
    }
    getExaminers() {
        return axios.get("profile/examiners");
    }
    getLotsCreators(){
        return axios.get("profile/analysts");
    }
    getGroups(){
        return axios.get("/user/groups");
    }
}

const userService = new UserService();

export default userService;
