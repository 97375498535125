
const breadcrumb = {
    tickets: { text: "Tickets", to: { name: "tickets" }, color: "" },
    contratos: { text: "Contratos", to: { name: "Contratos", color: "" } },
    asignarTickets: { text: "Asignar tickets", href: "asignartickets", color: "" },
    crearTicket: { text: "Crear Ticket", href: "crearticket", color: "" },
    editarTicket: { text: "Editar Ticket", href: "editarticket", color: "" },
    verTicket: { text: "Ver Ticket", href: "verticket", color: "" },
    verContratoTicket: { text: "Ver Detalle", href: "vercontratoticket", color: "" },
};

const routes = [
    {
        path: "/tickets",
        name: "tickets",
        component: () => import("@/views/Tickets/TicketsView.vue"),
        meta: {
            showSidebarTypificaciones: false,
            transparentCard: true,
            breadcrumbSeparate: true,
            breadcrumb: [breadcrumb.tickets],
            requiredPermissions: ["ver_tickets"],
            redirects: [
                {
                    permissions: ["crear_ticket"],
                    newRouteName: "creartickets"
                }
            ]
        },
    },
    {
        path: "/tickets/asignar",
        name: "asignartickets",
        component: () => import("@/views/Tickets/TicketsAssignView.vue"),
        meta: {
            breadcrumb: [breadcrumb.tickets, breadcrumb.asignarTickets],
            requiredPermissions: ["reasignar_lote"]
        },
    },
    {
        path: "/tickets/editar/:id",
        name: "editarticket",
        component: () => import("@/views/Tickets//TicketDetailView.vue"),
        meta: {
            breadcrumb: [breadcrumb.tickets, breadcrumb.editarTicket], readOnly: true,
        },
    },
    {
        path: "/tickets/crear",
        name: "creartickets",
        component: () => import("@/views/Tickets/SellerTicketView.vue"),
        meta: {
            breadcrumb: [breadcrumb.tickets],
            requiredPermissions: ["crear_ticket"]
        },
    },
    {
        path: "/tickets/crear/:id",
        name: "crearticket",
        component: () => import("@/views/Tickets/SellerCreateTicket.vue"),
        meta: {
            breadcrumb: [breadcrumb.tickets, breadcrumb.crearTicket],
            requiredPermissions: ["crear_ticket"]
        },
    },
    {
        path: "/tickets/ver/:id",
        name: "vercontratoticket",
        component: () => import("@/views/Tickets/SellerCreateTicket.vue"),
        meta: {
            breadcrumb: [breadcrumb.tickets, breadcrumb.verContratoTicket],
            requiredPermissions: ["crear_ticket"],
            readOnly: true,
        },
    },
    {
        path: "/contratoPozo/ver/:id",
        name: "vercontratopozo",
        component: () => import("@/views/Tickets/PozoContractDetail.vue"),
        meta: {
            breadcrumb: [breadcrumb.contratos, breadcrumb.verContratoTicket],
            requiredPermissions: ["crear_ticket"],
            readOnly: true,
        },
    },
    {
        path: "/tickets/:id",
        name: "verticket",
        component: () => import("@/views/Tickets/TicketDetailView.vue"),
        meta: {
            showSidebarTypificaciones: false,
            breadcrumb: [breadcrumb.tickets],
            requiredPermissions: ["ver_tickets"],
            readOnly: true,
        },
    },
];
export default routes;
