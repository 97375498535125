import { ANALYST_HEADERS, setAnalystCounts, formatNames } from "@/helpers/visaHelpers";
import ContractService from "@/services/ContractService";
import userService from "@/services/UserService";

export default {
  state: () => ({
    batch: undefined,
    contract: undefined,
    headersAnalystsTable: [],
    dataAnalystsTable: [],
    contractData: [],
    batchData: [],
    analysts: [],
    loading: false,
    visa: {},
    typifications: [],
    mandatoryFields: [],
    nextContractToVisa: undefined,
    vised: false,
    reassignAnalystIndex: undefined,
    updateContract: false
  }),
  mutations: {
    // Obtiene y define variables para la creacion de la tabla resumen de analistas con lotes asignados según combinación. (visar si) 
    // que se visualiza en la vista de Visado Lotes para perfiil Administrador
    formatDataForAdmin(state, data) {
      const headersAdded = ANALYST_HEADERS;
      const headersAnalystsTable = [
        { text: "Analistas", value: 'analyst', class: "greyBstLight3", cellClass: "greyBstLight2", width: "180px" }
      ];
      const dataAnalystsTable = [];
      const batchData = [];
      const contractData = [];
      const analysts = [];
      data.forEach(d => {
        if (d.batch && d.batch.length > 0) {
          const {
            id,
            nombres,
            apellidos,
            batch
          } = d;
          analysts.push({
            id,
            nombre: formatNames(nombres) + " " + formatNames(apellidos)
          });
          const countForUser = setAnalystCounts(id, nombres, apellidos);
          batch.forEach(b => {
            if (b.contracts && b.contracts.length > 0) {
              countForUser.batch++;
              countForUser.contracts += b.contracts.length;
              const contract = b.contracts[0];
              const {
                b2b,
                origen_base_id,
                portabilidad,
                tipo_venta_id,
              } = contract;
              let batchType = "";
              if (tipo_venta_id === 1) {
                batchType += "F";
              }
              else if (tipo_venta_id === 2) {
                if (portabilidad) {
                  batchType += "MP";
                }
                else {
                  batchType += "MSP";
                }
              }
              else if (tipo_venta_id === 3) {
                batchType += "TF";
              }
              else {
                batchType += "TM";
              }
              if (origen_base_id === 1) {
                batchType += "BA";
              }
              else {
                batchType += "BO";
              }
              if (b2b) {
                batchType += "B2B";
              }
              else {
                batchType += "B2C";
              }
              countForUser[batchType]++;
              if (!headersAdded[batchType].present) {
                headersAdded[batchType].present = true;
              }
              const batchContracts = []
              b.contracts.forEach(pendingContract => {
                const pendingContractData = {
                  ...pendingContract,
                  numero_lote: b.id,
                  analyst_id: id,
                  fecha_asignacion: b.fecha_asignacion,
                  estado_lote_id: b.estado_lote_id,
                  analyst: formatNames(nombres) + " " + formatNames(apellidos)
                }
                contractData.push(pendingContractData);
                batchContracts.push(pendingContractData);
              });
              batchData.push({
                ...b,
                ...batchContracts[0],
                contracts: batchContracts,
                batchType: batchType
              })
            }
          })
          dataAnalystsTable.push(countForUser);
        }
      })
      Object.values(headersAdded).forEach(h => {
        if (h.present) {
          headersAnalystsTable.push(h.header);
        }
      });
      headersAnalystsTable.push(
        { text: "Lotes", value: 'batch', class: "greyBstLight3", cellClass: "greyBstLight2" },
        { text: "Contratos", value: 'contracts', class: "greyBstLight3", cellClass: "greyBstLight2" }
      );
      state.headersAnalystsTable = headersAnalystsTable;
      state.dataAnalystsTable = dataAnalystsTable;
      state.batchData = batchData;
      state.contractData = contractData;
      state.loading = false;

    },
     // Obtiene y define variables para la creacion de la tabla de contratos/lotes propios, asignados a un analista para ser visados (visar si) 
    // que se visualiza en la vista de Visado Lotes para perfil de analista 
    formatDataForAnalyst(state, data) {
      const contractData = [];
      const batchData = [];
      data.forEach(contract => {
        if (contract.contracts && contract.contracts.length > 0) {
          const batchContracts = []
          contract.contracts.forEach(pendingContract => {
            const pendingContractData = {
              ...pendingContract,
              numero_lote: contract.id,
              estado_lote_id: contract.estado_lote_id,
              fecha_asignacion: contract.fecha_asignacion,
            }
            contractData.push(pendingContractData);
            batchContracts.push(pendingContractData);
          });
          batchData.push({
            numero_lote: contract.id,
            fecha_inicio_revision: contract.fecha_inicio_revision,
            fecha_final_revision: contract.fecha_final_revision,
            estado_lote_id: contract.estado_lote_id,
            ...contract.contracts[0],
            contracts: batchContracts
          })
        }
      });
      state.contractData = contractData;
      state.batchData = batchData;
      state.loading = false;
    },

    //Define y setea variables de estado de visación de un contrato/lote
    setContractVisa(state, payload) {
      const {
        contractId,
        visa,
        fecha_cambio_codigo
      } = payload;
      let nextContractToVisa = undefined;
      const batchUpdated = state.batch;
      batchUpdated.contracts = state.batch.contracts.map(contract => {
        if (contract.id == parseInt(contractId)) {
          contract.visa = visa;
          contract.fecha_cambio_codigo = fecha_cambio_codigo;
        }
        if (contract.visa === null && nextContractToVisa === undefined) {
          nextContractToVisa = contract.id;
        }
        return contract;
      })
      state.batch = batchUpdated;
      state.nextContractToVisa = nextContractToVisa;
      state.vised = true;
    },
    setUpdateContract(state, updateContract) {
      state.updateContract = updateContract;
    },
    setVisa(state, visa) {
      state.visa = visa;
    },
    setTypifications(state, typifications) {
      state.typifications = typifications;
    },
    addTypification(state, tipificacion) {
      if (state.typifications.length === 0) {
        state.typifications = [tipificacion];
      }
      else {
        const campoId = tipificacion.campo_id;
        let updated = false;
        const updatedTypifications = state.typifications.map(t => {
          if (t.campo_id == campoId) {
            updated = true;
            return tipificacion;
          }
          return t;
        })
        if (!updated) {
          updatedTypifications.push(tipificacion);
        }
        state.typifications = updatedTypifications;
      }
    },
    setMandatoryFields(state, mandatoryFields) {
      state.mandatoryFields = mandatoryFields;
    },
    clearVisaState(state) {
      state.batch = undefined;
    },
    setContract(state, contract) {
      state.contract = contract;
    },
    setVised(state, vised) {
      state.vised = vised;
    },
    setReassignAnalystIndex(state, index) {
      state.reassignAnalystIndex = index;
    }
  },
  getters: {
    getSelectedContract: (state) => (contractId) => {
      try {

        if (state.batch !== undefined) {
          const contractSelected = state.batch.contracts.find(contract => contract.id === parseInt(contractId));
          return contractSelected;
        }
        return undefined;
      } catch (error) {
        return undefined;
      }
    },
    getNextContractToVisaId: (state) => {
      const nextContractToVisa = state.batch.contracts.find(contract => contract.visa === null);
      if (nextContractToVisa) {
        return nextContractToVisa.id;
      }
      return undefined;
    },
    allContractVised: (state) => {
      if (state.batch && state.batch.contracts) {
        return state.batch.contracts.every(contract => contract.visa !== null);
      }
      return false;
    }
  },
  actions: {
    /** Descripcion: Obtiene contratos de un lote desde servicio 'getContractsOfBatch' ubicado en carpeta services -> ContractService.js
       * Input:
       * batchId: String - ID de lote
       * Return: Setea valores guardados en el State
       **/
    async getBatchAndContracts({ state, commit, dispatch }, batchId) {
      try {
        if (state.batch !== undefined) {
          if (state.batch.id === parseInt(batchId)) {
            commit('setUpdateContract', true);
            return
          }
          state.batch = undefined;
        }
        const response = await ContractService.getContractsOfBatch(batchId);
        state.batch = response.data.batch;
        commit('setUpdateContract', true);
      } catch (error) {
        dispatch("printErrors", error);
      }
    },
    /** Descripcion: 
     * Obtiene listado de usuario creador de lotes desde servicio 'getLotsCreators' en carpeta services -> UserService.js
     * Obtiene todos los contratos pendientes de visar según filtros entregados desde servicio 'getAllPending' en carpeta services -> ContractService.js
       * Input:
       * Return: Setea valores guardados en el State 
       **/
    async setVisaDataForAdmin({ state, commit, dispatch }, filters = {}) {
      try {
        state.loading = true;
        userService.getLotsCreators().then(response => {
          state.analysts = response.data.analistas.map(analista => {
            return {
              id: analista.id,
              nombre: formatNames(analista.nombres) + " " + formatNames(analista.apellidos)
            }
          });
        })
        const response = await ContractService.getAllPending(filters);
        const data = response.data.pendingContracts;
        commit("formatDataForAdmin", data);
      } catch (error) {
        dispatch("printErrors", error);
      }
    },
    /** Descripcion: Obtiene todos los contratos propios pendientes de visar de un analista desde servicio 'getPendingByUserId' en carpeta servies -> ContractService.js
       * Input:
       * Return: Setea valores guardados en el State 
       **/
    async setVisaDataForAnalyst({ state, commit, dispatch }, payload) {
      try {
        state.loading = true;
        const {
          userId,
          filters
        } = payload;
        const response = await ContractService.getPendingByUserId(userId, filters);
        const data = response.data.pendingContracts;
        commit("formatDataForAnalyst", data);
      } catch (error) {
        dispatch("printErrors", error);
      }
    },
    /** Descripcion: 
     * Función para visar un contrato, verificar si contrato está visado o no y determinar posicionamiento para seguir con siguiente contrato de lote sin visar
     * Determinar fecha de cambio de código una vez cerrado el lote
       * Input: 
       * contractId: Itenger - Id de contrato
       * Return: Setea valores guardados en el State 
       **/
    async visaContract({ state, commit, dispatch }, contractId) {
      try {
        const position = {};
        const visedContracts = state.batch.contracts.filter(contract => contract.visa !== null);
        if (visedContracts.length === 0) {
          position.first = true;
        }
        else if (visedContracts.length === state.batch.contracts.length - 1) {
          position.last = true;
        }
        const response = await ContractService.visaContract(contractId, state.visa, state.typifications, position);

        const visa = response.data.visa;
        const fecha_cambio_codigo = response.data.fecha_cambio_codigo;
        commit('setContractVisa', {
          contractId,
          visa,
          fecha_cambio_codigo
        });
        commit('setUpdateContract', true);
      } catch (error) {
        dispatch("printErrors", error);
      }
    },
    /** Descripcion: Obtiene informacion de un contrato desde servicio 'getContractById' en carpeta servies -> ContractService.js
       * Input:
       * contractId: Itenger - Id de contrato
       * Return: Setea valores guardados en el State 
       **/
    async getContract({ state, dispatch }, contractId) {
      try {
        const response = await ContractService.getContractById(contractId);
        state.contract = response.data.contr;
      } catch (error) {
        dispatch("printErrors", error);
      }
    }
  }
}
