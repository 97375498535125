export const publicRouteNames = ["login", "recuperar_contraseña"];


/** Descripcion: Funcion 'getAttrOrDefault' para retornar atributo existente
 * Input:  
 * attr: String 
 * Return: String - atributo si este existe, sino, retorna un simbolo '-'
 * **/
export function getAttrOrDefault(attr) {
  if (attr) {
    return attr;
  }
  return "-"; 
}

/** Descripcion: Funcion 'getSubAttrOrDefault' para retornar un atributo que es parte de otro
 * Input:  
 * attr: String - Atributo
 * field: String - Sub Atributo
 * Return: String - Subatributo si este existe, sino, retorna un simbolo '-'
 * **/
export function getSubAttrOrDefault(attr, field) {
  try {
    return getAttrOrDefault(attr[field]);
  } catch (error) {
    return "-";
  }
}

/** Descripcion: Funcion 'getSellType' para retornar tipo de venta al que pertenece un contrato
 * Input:  
 * sale: Number - ID de tipo de venta 
 * port: Boolean - Atributo de portabilidad
 * Return: String - Nombre de tipo de venta con o sin portabiliad si el atributo existe, sino, retorna simbolo '-'
 * **/
export function getSellType(sale, port) {
  if (sale === 1) {
    if (port === true) {
      return "Fijo (P)";
    } else {
      return "Fijo (SP)";
    }
    // return "Fijo";
  } else if (sale === 4) {
    if (port === true) {
      return "Televenta Móvil (P)";
    } else {
      return "Televenta Móvil (SP)";
    }
    // return "Televenta Móvil";
  } else if (sale === 3) {
    if (port === true) {
      return "Televenta Fijo (P)";
    } else {
      return "Televenta Fijo (SP)";
    }
    // return "Televenta Fijo";
  } else if (sale === 2) {
    if (port === true) {
      return "Móvil (P)";
    } else {
      return "Móvil (SP)";
    }
  }
  return "-";
}

/** Descripcion: Funcion 'scrollToTop' para desplazar el visor al principio de la pagina
 * Input:
 * Return:
 * **/
export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

/** Descripcion: Funcion 'formatedName' para formatear atributo nombres
 * Input:
 * name: String - Nombre 
 * Return: String - Nombre con Primera letra en mayúscula y el reto en minúscula
 * **/
export function formatedName(name) {
  let formatName = name.toLowerCase().split(" ");
  return formatName
    .map((p) => p.charAt(0).toUpperCase() + p.slice(1))
    .join(" ");
}

/** Descripcion: Funcion 'formatedRut' para formatear atributo RUT
 * Input:
 * rut: String - RUT
 * Return: String - RUT sin puntos y con guión para dígito verificador
 * **/
export function formatedRut(rut) {
  return [rut.slice(0, rut.length - 1), "-", rut.slice(-1).toUpperCase()].join(
    ""
  );
}

/** Descripcion: Funcion 'formatedRutforUser' para formatear atributo RUT
 * Input:
 * rut: String - RUT
 * Return: String - RUT con puntos y con guión para dígito verificador
 * **/
export function formatedRutforUser(rut) {
  let result = [
    rut
      .slice(0, rut.length - 2)
      .split(".")
      .join(""),
    rut.slice(rut.length - 1, rut.length),
  ].join("");
  return result;
}

/** Descripcion: Funcion 'formatRut' para formatear atributo RUT
 * Input:
 * rut: String - RUT
 * Return: String - RUT con puntos y con guión para dígito verificador
 * **/
export function formatRut(rut) {
  let newRut = [];
  rut = rut.replace(/[^0-9kK]/g, "");
  rut = rut.split("").reverse();
  rut.forEach((value, index) => {
    newRut.push(value);
    if (index % 3 === 0 && index !== 0 && index !== rut.length - 1) {
      newRut.push(".");
    }
  });
  newRut.splice(1, 0, "-");
  newRut = newRut.reverse().join("");
  return newRut;
}

/** Descripcion: Retrasa la ejecución de una función por un periodo predeterminado de tiempo
 * Input:
 * func: Function
 * wait: Tiempo de espera
 * Return: Function - funcion que invoca otra funcion después del tiempo de espera
 * **/
export function debounce(func, wait = 300) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

/** Descripcion: Funcion 'getResultState' obtener el nombre del estado de un contrato
 * Input:
 * state: Number - ID de estado de un contrato
 * Return: String - Nombre de estado de un conrato
 * **/
export function getResultState(state) {
  switch (state) {
    case 1:
      return "Receptado";
    case 2:
      return "En Revisión";
    case 3:
      return "Revisado";
    case 4:
      return "En Digitalización";
    case 5:
      return "Digitalizado";
    case 6:
      return "En Despacho";
    case 7:
      return "Despachado";
    case 8:
      return "Aprobado";
    case 9:
      return "Rechazado no aclarable";
    case 10:
      return "Fraude";
    case 11:
      return "Rechazado aclarable";
    case 12:
      return "Llamado Telefónico";
    case 13:
      return "Venta Anulada";
    case 14:
      return "Rechazado Fuera de Plazo";
    case 15:
      return "Despacho sin Visar";
    default:
      return "N/A";
  }
}
