import axios from "../axios-config";

class LoteService {
    uploadFile(formData) {
        return axios.post("batch/upload", formData, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
    }
    getAllLots(params, body) {
        return axios.post("batch/grouped", body, { params });
    }

    getCombinationSaleType(formData) {
        return axios.post("batch/getCombinations", { date: formData })
    }
    getOperationDays() {
        return axios.get("batch/getDays")
    }
    setOperationDays(newOpDay){
        return axios.put("batch/updateDay", newOpDay)
    }
    createBatch(data) {
        return axios.post("batch/new", data)
    }
    getBatchPending() {
        return axios.get("batch/pending")
    }

    getLotsCombination() {
        return axios.get("/batch/getLotsCombinations");
    }
    getBatchStatuses() {
        return axios.get("/batch/statuses");
    }
    assignLots(userId, batchesId) {
        return axios.post("/batch/assign/" + userId, {
            batch: batchesId,
        });
    }
    closeBatch(batchId) {
        return axios.post("/batch/close/" + batchId);
    }
}

const loteService = new LoteService();
export default loteService;

