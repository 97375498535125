const breadcrumb = {
	tipificaciones: { text: "Tipificaciones", to: { name: "tipificaciones" }, color: "" },
	editartipificacion: { text: "Editar Tipificación", href:"editartipificaciones", color: "" }
};

const routes = [
	{
		path: "/tipificaciones",
		name: "tipificaciones",
		component: () => import("@/views/Typifications/TypificationsView.vue"),
		meta: {
			transparentCard: true,
			breadcrumbSeparate: true,
			showSidebarTypificaciones: true,
			breadcrumb: [breadcrumb.tipificaciones],
			requiredPermissions: ["tipificar"]
		},
	},
	{
		path: "/tipificaciones/editar/:tipo_venta_id",
		name: "editartipificaciones",
		component: () => import("@/views/Typifications/ActionsView.vue"),
		meta: {
			transparentCard: true,
			breadcrumbSeparate: true,
			showSidebarTypificaciones: false,
			breadcrumb: [breadcrumb.tipificaciones, breadcrumb.editartipificacion],
			requiredPermissions: ["tipificar"]
		},
	},
];
export default routes;
