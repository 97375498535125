import axios from "../axios-config";

class RepositoryService {
  getAllRepository(params, body) {
    return axios.post("remoteSales/all", body, { params })
  }
  createRepository(data) {
    return axios.post("remoteSale/create", data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  getRepositoryById(id) {
    return axios.get("remoteSale/show/" + id)
  }
  editRepository(data, repoID) {
    return axios.post("remoteSale/edit/" + repoID, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }); 
  }
  getAttachedFile(id){
    return axios.get("remoteSale/download/" + id, {responseType: 'blob'}) 
  }
  getUserList(){
    return axios.get("profile/sellers")
  }
}

export default new RepositoryService();