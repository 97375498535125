const breadcrumb = {
	visados: { text: "Visados", href: "lotes", color: "" },
    visadosPozo: { text: "Visado Pozo", to: { name: "visadospozo" }, color: "" },
    asignarPozo: { text: "Asignar Pozo", href: "asignarvisadospozo", color: "" },
    visarContrato: { text: "Visar Contrato", href: "", color: "" },
};

const routes = [
	{
		path: "/visadoLotes",
		name: "visadolotes",
		component: () => import("@/views/Visa/LotsVisaView.vue"),
		meta: {
			breadcrumb: [breadcrumb.visados],
			transparentCard: true,
			breadcrumbSeparate: true,
			requiredPermissions: ["ver_lotes_pendientes", "ver_lotes_propios", "visar_lotes_propios"]
		},
	},
	{
		path: "/visadoLotes/:batchId/visar",
		name: "visadocontrato",
		component: () => import("@/views/Visa/VisadoView.vue"),
		meta: {
			breadcrumb: [breadcrumb.visados],
			requiredPermissions: ["visar_lotes_propios"]
		},
	},
	{
		path: "/visadoLotes/:contractId/ver",
		name: "vervisadocontrato",
		component: () => import("@/views/Visa/VisadoView.vue"),
		meta: {
			breadcrumb: [breadcrumb.visados],
			readOnly: true,
			requiredPermissions: ["ver_lotes_pendientes", "ver_lotes_propios"]
		},
	},
	{
		path: "/visadoLotes/:contractId/editar",
		name: "editarvisadocontrato",
		component: () => import("@/views/Visa/VisadoView.vue"),
		meta: {
			breadcrumb: [breadcrumb.visados],
			readOnly: true,
			showEditForm: true,
			requiredPermissions: ["cambiar_resultado_contrato"]
		},
	},
	{
		path: "/visadosPozo",
		name: "visadospozo",
		component: () => import("@/views/VisaPozo/VisaPozoView.vue"),
		meta: {
			transparentCard: true,
			breadcrumbSeparate: true,
			breadcrumb: [breadcrumb.visadosPozo],
			requiredPermissions: ["ver_contratos_digitales", "ver_contratos_propios"]
		},
	},
	{
		path: "/visadosPozo/asignar",
		name: "asignarvisadospozo",
		component: () => import("@/views/VisaPozo/AssignPozoView.vue"),
		meta: {
			transparentCard: true,
			breadcrumbSeparate: true,
			breadcrumb: [breadcrumb.visadosPozo, breadcrumb.asignarPozo],
			requiredPermissions: ["asignar_contratos"]
		},
	},
	{
		path: "/visadosPozo/:analistNumber/visar",
		name: "visarpozo",
		component: () => import("@/views/VisaPozo/VisarPozoAnalistaView.vue"),
		meta: {
			breadcrumb: [breadcrumb.visadosPozo, breadcrumb.visarContrato],
			requiredPermissions: ["cambiar_resultado_contrato", "visar_contrato_digital"]
		},
	},
	{
		path: "/visadosPozo/:id",
		name: "contratovisadopozo",
		component: () => import("@/views/VisaPozo/PozoVisadoView.vue"),
		meta: {
			breadcrumb: [breadcrumb.visadosPozo],
			requiredPermissions: ["ver_contratos_digitales", "ver_contratos_propios"]
		},
	},
];
export default routes;
