// src/plugins/vuetify.js
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
// Translation provided by Vuetify (javascript)
import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#055D86",
        primaryvariant: "#4E9CDF",
        lightblue: "#ECF9FF",
        secondary: "#FFD800",
        accent: "#8c9eff",
        error: "#b71c1c",
        background: "#ECF9FF",
        greenBst: "#00B677",
        greenBstLight: "#E2FFF5",
        greenBstLight2: "#D6F2E9",
        greyBst: "#8c8c8c",
        greyBstLight: "#F2F2F2",
        greyBstLight2: "#F7F7F7",
        greyBstLight3: "#EEEEEE",
        yellowBst: "#EECA03",
        yellowBstLight: "#FFFCEA",
        yellowBstLight2: "#F2EFDE",
        orangeBst: "#E99248",
        orangeBstLight: "#FFEEE0",
        orangeBstLight2: "#F2E2D5",
        blueBst: "#4E9CDF",
        blueBstLight: "#DFF0FF",
        blueBstLight2: "#D4E4F2",
        violetBst: "#673AB7",
        violetBstLight: "#EFE5FF",
        violetBstLight2: "#E0D6F0",
        darkBlueBst: "#004D73",
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280,
    },
  },
  icons: {
    iconfont: "mdi", // 'mdiSvg',  || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    locales: { es },
    current: "es",
  },
});
export default vuetify;
