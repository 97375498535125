import axios from "../axios-config";

class TypificationService {
    getTipoVisados() {
        return axios.get("tipificaciones/tipoVisados");
    }
    getInstancias() {
        return axios.get("tipificaciones/instancias");
    }
    getAgrupaciones() {
        return axios.get("tipificaciones/agrupaciones");
    }
    getEstadoContratos() {
        return axios.get("tipificaciones/estadoContratos");
    }
    getSecciones(params) {
        return axios.get("tipificaciones/secciones", { params });
    }
    getCanales() {
        return axios.get("tipificaciones/canales");
    }
    getEmpresasDonadoras() {
        return axios.get("tipificaciones/empresasDonadoras");
    }
    getColumnasVisado(tipo_venta_id) {
        return axios.get("tipificaciones/visadoCampos/" + tipo_venta_id);
    }
    getSeccionesByTipoVentaId(tipoVentaId, trash=true) {
        const params = {
            value: trash
        }
        return axios.get("tipificaciones/secciones/" + tipoVentaId, { params });
    }
    crearSeccion(seccion) {
        return axios.post("tipificaciones/seccion/crear", seccion);
    }
    crearCampo(campo) {
        return axios.post("tipificaciones/campo/crear", campo);
    }
    crearMotivo(motivo) {
        return axios.post("tipificaciones/motivoRechazo/crear", motivo);
    }
    crearResponsable(responsable) {
        return axios.post("tipificaciones/responsable/crear", responsable);
    }
    editarSeccion(seccion) {
        return axios.put("tipificaciones/seccion/editar/" + seccion.id, seccion);
    }
    editarCampo(campo) {
        return axios.put("tipificaciones/campo/editar/" + campo.id, campo);
    }
    editarMotivo(motivo) {
        return axios.put("tipificaciones/motivoRechazo/editar/" + motivo.id, motivo);
    }
    editarResponsable(responsable) {
        return axios.put("tipificaciones/responsable/editar/" + responsable.id, responsable);
    }
    borrarSeccion(id) {
        return axios.post("tipificaciones/seccion/borrar/" + id);
    }
    borrarCampo(id) {
        return axios.post("tipificaciones/campo/borrar/" + id);
    }
    borrarMotivo(id) {
        return axios.post("tipificaciones/motivoRechazo/borrar/" + id);
    }
    borrarResponsable(id) {
        return axios.post("tipificaciones/responsable/borrar/" + id);
    }
}

export default new TypificationService();
