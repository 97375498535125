import authService from "@/services/AuthService";
import userService from "@/services/UserService";
import loteService from "@/services/LoteService.js";

export default {
  /** Descripcion: Funcion 'login' para inicio de sesión desde servicio 'login' en carpeta services - AuthService.js
   * guarda token de inicio de sesion e informacion del usuario logeado
    * Input:
    * data: string - Nombre y password de usuario
    * Return: 
    **/
  async login({ commit, dispatch }, data) {
    let response = await authService.login(data);
    if (response.status == 201) {
      localStorage.setItem("token", response.data.token);
      await dispatch("getUserInfo");
      commit("authenticate", {
        token: response.data.token,
      });
    }
  },
  /** Descripcion: Función 'logout' remueve token e info de usuario guardado en el localStorage para cerrar sesión desde servicio 'logout' en carpeta services -> AuthService.js
    * Input:
    * Return: 
    **/
  async logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    await authService.logout();
  },
  /** Descripcion: funcion 'getUserInfo' obtiene ID del usuario logeado desde servicio 'getUserInfo' en carpeta services -> UserService.js
  * Input:
  * Return: setea valor 'userInfo' guardado en el state.js
  **/
  async getUserInfo({ state }) {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    let response = await userService.getUserInfo(token);

    if (response.status == 200) {
      state.userInfo = response.data;
      localStorage.setItem('userInfo', JSON.stringify(response.data));
      return response.data;
    }
  },
  /** Descripcion: funcion 'getOperationDays' obtiene los dias de operacion según tipo de negocio desde servicio 'getOperationDays' en carpeta services -> LoteService.js
  * Input:
  * Return: Setea valor 'operationDays' guardado en el State.js
  **/
  async getOperationDays({ state, dispatch }) {
    const defaultOp = {
      b2b: "5",
      b2c: "2",
    }
    try {
      const resp = await loteService.getOperationDays();

      if (resp.status === 204) {
        state.operationDays = { ...defaultOp }
      }
      else {
        const b2bDay = resp.data.days.find((item) => {
          return item.nombre == "B2B";
        });
        const b2cDay = resp.data.days.find((item) => {
          return item.nombre == "B2C";
        });

        state.operationDays = {
          b2b: b2bDay.valor,
          b2c: b2cDay.valor,
        };
      }

    } catch (error) {
      state.operationDays = { ...defaultOp }
      dispatch("printErrors", error);
    }
  },
  /** Descripcion: funcion 'getCombinations' obtiene combinaciones de lotes por fecha de creacion desde servicio 'getCombinationSaleType' en carpeta services -> LoteService.js
  * Input:
  * Return: Setea valor 'combinationList' guardado en el State.js
  **/
  async getCombinations({ state, dispatch }) {
    try {
      state.isLoading = true;
      state.dataNotFound = false;
      state.dataPickerDisabled = true;
      const response = await loteService.getCombinationSaleType(
        state.creationDate
      );
      if (Array.isArray(response.data)) {
        state.combinationList = response.data;
      } else {
        state.combinationList = Object.values(response.data);
      }
      state.combinationList.forEach((comb) => {
        comb.loading = false;
      });
      state.isLoading = false;
      state.dataPickerDisabled = false;
      if (state.combinationList.length < 1) {
        state.dataNotFound = true;
      }

    } catch (error) {
      dispatch("printErrors", error);
      //console.error(error.response.data.errors);
    }
  },
  /** Descripcion: funcion 'getBatchCreated' obtiene Lotes creados desde servicio 'getAllLots' en carpeta services -> LoteService.js
  * Input:
  * Return: Setea valor 'tableData' guardado en el State.js
  **/
  async getBatchCreated({ state, dispatch }) {
    try {
      state.isLoadingTable = true;
      const response = await loteService.getAllLots();
      const responseData = response.data.pagination.data;
      state.tableData = responseData.filter((elem) => {
        return (
          elem.estado_lote === "CREADO"
          // && elem.creacion_lote === this.creationDate
        );
      });
      state.isLoadingTable = false;

    } catch (error) {
      dispatch("printErrors", error);
      //console.error(error.response.data.errors);
    }
  },
  /** Descripcion: funcion 'printErrors' entrega información de los tipos de errores al consultar servicios
    * Input:
    * Return:
    **/
  printErrors({ }, error) {
    if (error.response) {
      // Request made and server responded
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
  }

};
