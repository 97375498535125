import axios from "../axios-config";

class PozoService {
  getAllPozos(params, value) {
    return axios.post("pozo/all", value, { params });
  }

  getAllPozosById(params, value) {
    return axios.post("pozo/allById", value, { params });
  }

  getUnassignedContracts() {
    return axios.get("/pozo/unassigned");
  }

  getPozoAnalists(params) {
    return axios.get("/pozo/analists", { params });
  }

  getPozoSales() {
    return axios.get("saleType/all");
  }
  getPozoStates() {
    return axios.get("/pozo/getStatuses");
  }

  getUserPending(id) {
    return axios.get("/pozo/userPending/" + id);
  }

  getUsersPending() {
    return axios.get("/pozo/allPending");
  }

  reassignAnalist(params){
    return axios.put("pozo/reassign/", params);
  }

  reassignContracts(params){
    
    return axios.put("pozo/reassignPozo", params);
  }
  

  assignPozoContract(id, contracts) {
  	return axios.post("/pozo/assign/" + id, {
        contracts: contracts,
      });
  }

  getContract(id_analist){
    return axios.post("/pozo/start",{id:id_analist})
  }
  
  getContractState(){
    return axios.get("/contract/states")
  }

  getPozoObservations(){
    return axios.get("/pozo/getObservations")
  }

  getPozoResults(){
    return axios.get("/pozo/getResults")
  }

  editPozo(id,params){
    return axios.put("/pozo/edit/"+ id,params)
  }

  getPozo(id){
    return axios.get("/pozo/show/"+ id)
  }

  getContractStatesPozo(pozoId) {
    return axios.get(`/pozo/${pozoId}/states`);
  }
}

const pozoService = new PozoService();
export default pozoService;
