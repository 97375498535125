import axios from "../axios-config";

class StaticService {
    getSaleTypes() {
        return axios.get("saleType/all");
    }
    
}

let staticService = new StaticService();
export default staticService;