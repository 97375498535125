<template>
    <v-app id="app" :style="{ background: $vuetify.theme.themes.light.background }">
        <router-view v-if="loadPage" />
        <v-row align="center" v-else>
            <v-col cols="2">
                <v-skeleton-loader
                    type="card-avatar, article@2, list-item@4, actions"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="8">
                <v-skeleton-loader
                    type="card, date-picker-days, article@2, actions"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="2">
                <v-skeleton-loader
                    type="image, list-item@4, card-heading@3, actions"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
    </v-app>
</template>
<script>
import { mapState, mapActions } from "vuex";
//import { publicRouteNames } from "@/helpers/generalHelpers";

export default {
    methods: {
        ...mapActions(["getUserInfo"]),
    },
    computed: {
        ...mapState(["userInfo"]),
    },
    data() {
        return {
            loadPage: true,
        };
    },
    async mounted() {
        /* Se hace este watch debido a que por algún motivo, en este mounted no se obtiene el
        // nombre de la ruta.
        const unwatch = this.$watch(
            () => this.$route,
            async (route) => {
                // Se deja de hacer el watch
                unwatch();
                if (!publicRouteNames.includes(route.name)) {
                    if (!this.userInfo) {
                        await this.getUserInfo();
                    }
                }
                this.loadPage = true;
            }
        );*/
    },
};
</script>
