import repositoryService from "@/services/RepositoryService";

export default {
  state: () => ({
    // repositorio: { ...auxRepositorio },
    editRepo: "",
    
  }),
  mutations: {
    setEditRepo(state, data) {
      if (!data) data = {};
      state.editRepo = {
        attacheds: data.attacheds,
        nombres_vendedor: data.nombres_vendedor,
        apellidos_vendedor: data.apellidos_vendedor,
        created_at: data.created_at,
        estado_nombre: data.estado_nombre,
        id: data.id,
        iden_transaccion: data.iden_transaccion,
        rut_cliente: data.rut_cliente,
        updated_at: data.updated_at,
      }
    }
  },
  actions: {
    
  }
}