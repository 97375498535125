export default {
	authenticate(state, data) {
		state.token = data.token;
	},
	setUserInfo(state, userInfo) {
		localStorage.setItem("user_info", JSON.stringify(userInfo));
		state.userInfo = userInfo;
	},
	setSuccess(state, data) {
		state.showSuccess = data;
		setTimeout(() => {
			state.showSuccess = "";
		}, 3000);
	},
	setError(state, data) {
		state.showError = data;
		setTimeout(() => {
			state.showError = "";
		}, 4000);
	},
	showSidebar(state, data) {
		state.sidebar = data;
	},
	setEditUser(state, data) {
		if (!data) data = {};
		state.editUser = {
			id: data.id,
			nombres: data.nombres,
			apellidos: data.apellidos,
			rut: data.rut,
			password: undefined,
			emails: data.email == undefined ? [] : data.email.split(";"),
			profilesId: [],
			grupos:data.grupos ? data.grupos.map(grupo => grupo.id) : [],
			user: data.login,
			profiles: data.profiles,
			chileno: data.chileno,
		};
	},
	setEditProfile(state, data) {
		if (!data) data = {};
		let permissionsId;
		if (data.permissionsId) permissionsId = data.permissionsId;
		permissionsId = [];
		let permissions = data.permissions || data.permisos;
		if (permissions) {
			permissions.forEach((permission) => {
				permissionsId.push(permission.id);
			});
		}
		state.editProfile = {
			id: data.id,
			name: data.nombre,
			description: data.descripcion,
			permissionsId,
		};
	},
	setUsersCreators(state, data) {
		state.usersCreators = data;
	},
	setUsersCreatorsSelected(state, data) {
		state.usersCreatorsSelected = data;
	},

	setUsersCreatorsSelectedTotalLots(state, { totalLots, rest, combinationKey }) {
		let totalLotsAux = totalLots;
		let lotsIndex = 0;
		for (let user of state.usersCreatorsSelected) {
			if (rest > 0) {
				totalLotsAux = totalLots + 1;
				rest -= 1;
			}

			// Colocar el detalle de los lotes a cada usuario
			let sliced = state.lotsCombination[combinationKey].batches.slice(lotsIndex, lotsIndex + totalLotsAux);
			user[combinationKey].batches.push(...sliced);
			lotsIndex += totalLotsAux;

			user[combinationKey].assigned = totalLotsAux;
			totalLotsAux = totalLots;
		}
	},

	resetUsersCreatorsSelectedTotalLots(state, { combinationKey }) {
		for (let user of state.usersCreatorsSelected) {
			user[combinationKey].batches = [];
			user[combinationKey].assigned = 0;
		}
	},
	setCreationDate(state, date) {
		state.creationDate = date;
	},

	setLotsCombination(state, data) {
		state.lotsCombination = data;
	},

	setCombinations(state, data) {
		state.combinations = data;
	},

	setCombinationsLots(state, { lots, index }) {
		state.combinations[index].lots = lots;
	},

	resetCombinationsLots(state, { index }) {
		state.combinations[index].lots = state.combinations[index].lotsCopy;
	},

	setTotalLotsCount(state, data) {
		state.totalLotsCount = data;
	},

	setEmptyLots(state, data) {
		state.emptyLots = data;
	},

	setOperationDays(state, data) {
		state.operationDays = data;
	},

	setMode(state, mode) {
		state.mode = mode;
	},

	setCombinationsList(state, data) {
		state.combinationList = data;
	},

	setUsersCreatorsSelectedTotalTickets(state, { ticketsPerAnalist, rest, ticketsId }) {
		let ticketsPerAnalistAux = ticketsPerAnalist;
		let ticketIndex = 0;
		for (let user of state.usersCreatorsSelected) {
			let sliced = null;
			if (rest > 0) {
				ticketsPerAnalistAux = ticketsPerAnalist + 1;
				user["ticketsTotales"] = ticketsPerAnalistAux;
				sliced = ticketsId.slice(ticketIndex, ticketIndex + ticketsPerAnalistAux);
				ticketIndex += ticketsPerAnalistAux;
				rest -= 1;
			} else {
				user["ticketsTotales"] = ticketsPerAnalist;
				sliced = ticketsId.slice(ticketIndex, ticketIndex + ticketsPerAnalist);
				ticketIndex += ticketsPerAnalist;
			}
			const ticketsIdSliced = sliced.map((ticket)=>{
				return ticket.id
			})
			const ticketsSliced = {
				tickets: [... ticketsIdSliced]
			}
			user["ticketsAsignados"] = ticketsSliced;
		}
	},
};
