import Vue from "vue";
import VueRouter from "vue-router";
import { publicRouteNames } from "@/helpers/generalHelpers";

import UsuariosRoutes from "@/router/routes/usuarios";
import PerfilesRoutes from "@/router/routes/perfiles";
import VisadosRoutes from "@/router/routes/visados";
import TipificacionesRoutes from "@/router/routes/tipificaciones";
import TicketsRoutes from "@/router/routes/tickets";
import RepositoriosRoutes from "@/router/routes/repositorios";
import LotesRoutes from "@/router/routes/lotes";
import ReportesRoutes from "@/router/routes/reportes";

Vue.use(VueRouter);

let baseRoutes = [
	{
		path: "/",
		name: "login",
		meta: { hideNavbar: true },
		component: () => import("@/views/Login/LoginView.vue"),
	},
  {
		path: "/recuperar_password",
		name: "recuperar_contraseña",
		meta: { hideNavbar: true },
		component: () => import("@/views/Login/ResetPasswordView.vue"),
	},
	{
		path: "/inicio",
		name: "layout",
		component: () => import("@/components/Layout/DashboardLayout.vue"),
		meta: {
			showSidebarUsers: true,
			showSuccessCreated: false,
		},
		children: [
			{
				path: "/inicio",
				name: "inicio",
				component: () => import("@/views/Dashboard/DashboardNewView.vue"),
				meta: {
					showSidebarUsers: true,
					showSuccessCreated: false,
				},
			},
		],
	},
	{
		path: "/inicio",
		name: "layout",
		component: () => import("@/components/Layout/AppLayout.vue"),
		children: [
			...UsuariosRoutes,
			...PerfilesRoutes,
			...VisadosRoutes,
			...TipificacionesRoutes,
			...TicketsRoutes,
			...RepositoriosRoutes,
			...LotesRoutes,
			...ReportesRoutes,
		],
	},
  
]

/*Vue.use(VueRouter);

 const profile = getProfile();

const breadcrumb = {
  usuario: { text: "Usuarios", to: { name: "usuarios" }, color: "" },
  crearUsuario: { text: "Crear usuario", href: "crearusuario", color: "" },
  editarUsuario: { text: "Editar usuario", href: "editarusuario", color: "" },
  verUsuario: { text: "Ver usuario", href: "verusuario", color: "" },

  perfil: { text: "Perfiles", to: { name: "perfiles" }, color: "" },
  crearPerfil: { text: "Crear perfil", href: "crearperfil", color: "" },
  editarPerfil: { text: "Editar perfil", href: "editarperfil", color: "" },
  verPerfil: { text: "Ver perfil", href: "verperfil", color: "" },

  visados: { text: "Visados", to: { name: "lotes" }, color: "" },
  visadosPozo: { text: "Visado Pozo", href: "visadospozo", color: "" },
  asignarPozo: {
    text: "Asignar Pozo",
    href: "asignar_visados_pozo",
    color: "",
  },
  visar_contrato: { text: "Visar Contrato", href: "", color: "" },

  tipificaciones: {
    text: "Tipificaciones",
    to: { name: "tipificaciones" },
    color: "",
  },
  tickets: { text: "Tickets", href: "tickets", color: "" },
  asignarTickets: {
    text: "Asignar tickets",
    href: "asignartickets",
    color: "",
  },
  crearTicket: { text: "Crear Ticket", href: "crearticket", color: "" },

  repositorio: { text: "Repositorio", to: { name: "repositorio" }, color: "" },
  crearRepositorio: {
    text: "Crear repositorio",
    href: "crearrepositorio",
    color: "",
  },
  verRepositorio: {
    text: "Ver repositorio",
    href: "verrespositorio",
    color: "",
  },
  editarRepositorio: {
    text: "Editar repositorio",
    href: "editarrespositorio",
    color: "",
  },

  lote: { text: "Lotes", to: { name: "lotes" }, color: "" },
  crearLote: { text: "Crear lotes", href: "crearlote", color: "" },
  lotes: { text: "Lotes", href: "lotes", color: "" },
  asignarLotes: { text: "Asignar lotes", href: "asignarlotes", color: "" },

  visados: { text: "Visados", to: { name: "lotes" }, color: "" },
  visadosPozo: { text: "Visado Pozo", href: "visados_pozo", color: "" },
  asignarPozo: {
    text: "Asignar Pozo",
    href: "asignar_visados_pozo",
    color: "",
  },

  tipificaciones: {
    text: "Tipificaciones",
    to: { name: "tipificaciones" },
    color: "",
  },
  tickets: { text: "Tickets", href: "tickets", color: "" },
  asignarTickets: {
    text: "Asignar tickets",
    href: "asignartickets",
    color: "",
  },
  crearTicket: { text: "Crear Ticket", href: "crearticket", color: "" },
  informes: { text: "Informes", href: "informes", color: "" },
  visadoDeLotes: { text: "Visado de Lotes", href: "visadodelotes", color: "" },
};

const routes = [
  {
    path: "*",
    name: "notFound",
    component: () => import("../components/Common/NotFound.vue"),
  },
  {
    path: "/",
    name: "login",
    meta: { hideNavbar: true },
    component: () => import("../views/Login/LoginView.vue"),
  },
  {
    path: "/inicio",
    name: "layout",
    component: () => import("../components/Layout/DashboardLayout.vue"),
    meta: {
      showSidebarUsers: true,
      showSuccessCreated: false,
      breadcrumb: [breadcrumb.usuario],
    },
    children: [
      {
        path: "/inicio",
        name: "inicio",
        component: () => import("../views/Dashboard/DashboardNewView.vue"),
        meta: {
          showSidebarUsers: true,
          showSuccessCreated: false,
          breadcrumb: [breadcrumb.usuario],
        },
      },
    ],
  },
  {
    path: "/inicio",
    name: "layout",
    component: () => import("../components/Layout/AppLayout.vue"),
    children: [
      /* -------------------------------------------------------------------------- */
      /*                                  usuarios                                  */
      /* --------------------------------------------------------------------------
      {
        path: "/usuarios",
        name: "usuarios",
        component: () => import("../views/User/AllUsersView.vue"),
        meta: {
          showSidebarUsers: true,
          showSuccessCreated: false,
          breadcrumb: [breadcrumb.usuario],
        },
      },
      {
        path: "/crearusuario",
        name: "crearusuario",
        component: () => import("../views/User/CreateUserView.vue"),
        meta: { breadcrumb: [breadcrumb.usuario, breadcrumb.crearUsuario] },
      },
      {
        path: "/editarusuario/:id",
        name: "editarusuario",
        component: () => import("../views/User/CreateUserView.vue"),
        meta: { breadcrumb: [breadcrumb.usuario, breadcrumb.editarUsuario] },
      },
      {
        path: "/verusuario/:id",
        name: "verusuario",
        component: () => import("../views/User/CreateUserView.vue"),
        meta: { breadcrumb: [breadcrumb.usuario, breadcrumb.verUsuario] },
      },
      /* -------------------------------------------------------------------------- */
      /*                                  perfiles                                  */
      /* --------------------------------------------------------------------------
      {
        path: "/perfiles",
        name: "perfiles",
        component: () => import("../views/Profile/AllProfilesView.vue"),
        meta: {
          showSidebarProfiles: true,
          showSuccessCreated: false,
          breadcrumb: [breadcrumb.perfil],
        },
      },
      {
        path: "/crearperfil",
        name: "crearperfil",
        component: () => import("../views/Profile/CreateProfileView.vue"),
        meta: { breadcrumb: [breadcrumb.perfil, breadcrumb.crearPerfil] },
      },
      {
        path: "/editarperfil/:id",
        name: "editarperfil",
        component: () => import("../views/Profile/CreateProfileView.vue"),
        meta: { breadcrumb: [breadcrumb.perfil, breadcrumb.editarPerfil] },
      },
      {
        path: "/verperfil/:id",
        name: "verperfil",
        component: () => import("../views/Profile/CreateProfileView.vue"),
        meta: { breadcrumb: [breadcrumb.perfil, breadcrumb.verPerfil] },
      },
      /* -------------------------------------------------------------------------- */
      /*                                    lotes                                   */
      /* --------------------------------------------------------------------------
      {
        path: "/lotes",
        name: "lotes",
        component: () => import("../views/Lotes/AllLotsView.vue"),
        meta: { showSuccessCreated: false, breadcrumb: [breadcrumb.lotes] },
      },
      {
        path: "/crearlotes",
        name: "crearlotes",
        component: () => import("../views/Lotes/CreateLotesView.vue"),
        meta: {
          transparentCard: true,
          breadcrumbSeparate: true,
          showSidebarUsers: true,
          showSuccessCreated: false,
          breadcrumb: [breadcrumb.lote, breadcrumb.crearLote],
        },
      },
      {
        path: "/asignarlotes",
        name: "asignarlotes",
        component: () => import("../views/Lotes/AssignLotsView.vue"),
        meta: {
          transparentCard: true,
          breadcrumbSeparate: true,
          breadcrumb: [breadcrumb.lotes, breadcrumb.asignarLotes],
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                                   visado                                   */
      /* --------------------------------------------------------------------------
      {
        path: "/visado-lotes",
        name: "visado-lotes",
        component: () => import("../views/Visa/LotsVisaView.vue"),
        meta: { breadcrumb: [breadcrumb.visados] },
      },
      {
        path: "/visados/:batchId",
        name: "visado_contrato",
        component: () => import("../views/Visa/VisadoView.vue"),
        meta: { breadcrumb: [breadcrumb.visados] },
      },
      {
        path: "/visados/:contractId/ver",
        name: "ver_visado_contrato",
        component: () => import("../views/Visa/VisadoView.vue"),
        meta: { breadcrumb: [breadcrumb.visados], readOnly: true },
      },
      {
        path: "/visados/:contractId/editar",
        name: "editar_visado_contrato",
        component: () => import("../views/Visa/VisadoView.vue"),
        meta: {
          breadcrumb: [breadcrumb.visados],
          readOnly: true,
          showEditForm: true,
        },
      },
      {
        path: "/visados_pozo",
        name: "visados_pozo",
        component: () => import("../views/VisaPozo/VisaPozoView.vue"),
        meta: {
          transparentCard: true,
          breadcrumbSeparate: true,
          breadcrumb: [breadcrumb.visadosPozo],
        },
      },
      {
        path: "/asignar_visados_pozo",
        name: "asignar_visados_pozo",
        component: () => import("../views/VisaPozo/AssignPozoView.vue"),
        meta: {
          transparentCard: true,
          breadcrumbSeparate: true,
          breadcrumb: [breadcrumb.visadosPozo, breadcrumb.asignarPozo],
        },
      },
      {
        path: "/visar_pozo/:analistNumber",
        name: "visar_pozo",
        component: () => import("../views/VisaPozo/VisarPozoAnalistaView.vue"),
        meta: {
          breadcrumb: [breadcrumb.visadosPozo, breadcrumb.visar_contrato],
        },
      },
      {
        path: "/contrato_visado_pozo/:batchNumber",
        name: "contrato_visado_pozo",
        component: () => import("../views/VisaPozo/PozoVisadoView.vue"),
        meta: { breadcrumb: [breadcrumb.visadosPozo] },
      },
      /* -------------------------------------------------------------------------- */
      /*                                   tipificaciones                                   */
      /* --------------------------------------------------------------------------
      {
        path: "/tipificaciones",
        name: "tipificaciones",
        component: () => import("../views/Typifications/TypificationsView.vue"),
        meta: { breadcrumb: [breadcrumb.tipificaciones] },
      },
      {
        path: "/tipificaciones/editar/:tipo_venta_id",
        name: "editar_tipificaciones",
        component: () => import("../views/Typifications/ActionsView.vue"),
        meta: {
          transparentCard: true,
          breadcrumbSeparate: true,
          showSidebarTypificaciones: false,
          breadcrumb: [breadcrumb.tipificaciones],
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                                   tickets                                  */
      /* --------------------------------------------------------------------------
      {
        path: "/tickets",
        name: "tickets",
        component: () => import("../views/Tickets/TicketsView.vue"),
        meta: {
          transparentCard: true,
          breadcrumbSeparate: true,
          showSidebarTypificaciones: false,
          breadcrumb: [breadcrumb.tickets],
        },
      },
      {
        path: "/tickets/:id",
        name: "ticket-detail",
        component: () => import("../views/Tickets/TicketDetailView.vue"),
        meta: {
          showSidebarTypificaciones: false,
          breadcrumb: [breadcrumb.tickets],
        },
      },
      {
        path: "/asignartickets",
        name: "asignartickets",
        component: () => import("../views/Tickets/TicketsAssignView.vue"),
        meta: { breadcrumb: [breadcrumb.tickets, breadcrumb.asignarTickets] },
      },
      {
        path: "/creartickets",
        name: "creartickets",
        component: () => import("../views/Tickets/SellerTicketView.vue"),
        meta: { breadcrumb: [breadcrumb.tickets] },
      },
      {
        path: "/crearticket/:contractNumber",
        name: "crearticket",
        component: () => import("../views/Tickets/SellerCreateTicket.vue"),
        meta: { breadcrumb: [breadcrumb.tickets, breadcrumb.crearTicket] },
      },
      /* -------------------------------------------------------------------------- */
      /*                                   repositorio                                  */
      /* --------------------------------------------------------------------------
      {
        path: "/repositorio",
        name: "repositorio",
        component: () => import("../views/Repository/RepositoryView.vue"),
        meta: {
          showSideRepositoryComponent: true,
          breadcrumb: [breadcrumb.repositorio],
        },
      },
      {
        path: "/crearrepositorio",
        name: "crearrepositorio",
        component: () => import("../views/Repository/CreateRepository.vue"),
        meta: {
          breadcrumb: [breadcrumb.repositorio, breadcrumb.crearRepositorio],
        },
      },
      {
        path: "/verrepositorio/:id",
        name: "verrepositorio",
        component: () => import("../views/Repository/CreateRepository.vue"),
        meta: {
          breadcrumb: [breadcrumb.repositorio, breadcrumb.verRepositorio],
        },
      },
      {
        path: "/repostorio/editar/:id",
        name: "editarrepositorio",
        component: () => import("../views/Repository/CreateRepository.vue"),
        meta: {
          breadcrumb: [breadcrumb.repositorio, breadcrumb.editarRepositorio],
        },
      },
      /* -------------------------------------------------------------------------- */
      /*                                   reportes                                  */
      /* --------------------------------------------------------------------------
      {
        path: "/informes/visado-lotes",
        name: "reporteslotes",
        component: () => import("../views/Reports/LotsReportsView.vue"),
        meta: {
          breadcrumb: [breadcrumb.informes, breadcrumb.visadoDeLotes],
        },
      },
    ],
  },
>>>>>>> origin/dev
];
*/
const routes = baseRoutes;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function userHavePermissions(permissionTags) {
  const userInfoString = localStorage.getItem("userInfo");
  if(userInfoString) {
    const userInfo = JSON.parse(userInfoString);
    if(userInfo.permissions && userInfo.permissions.length > 0) {
      return userInfo.permissions.some(permission => permissionTags.includes(permission.etiqueta));
    }
    else {
      return getProfile() === "Administrador"
    }
  }
	return false;
}
function getProfile() {
  const userInfoString = localStorage.getItem("userInfo");
  if(userInfoString) {
    const userInfo = JSON.parse(userInfoString);
    return userInfo.profiles[0].nombre;
  }
	return "";
}
function canAccess(requiredPermissions) {
	if(requiredPermissions) {
		return userHavePermissions(requiredPermissions);
	}
	return true;
}

function haveRedirectForPermissions(redirects) {
  const redirect = redirects.find(r => canAccess(r.permissions));
  if(redirect) {
    return redirect.newRouteName;
  }
  return "";
}

router.beforeEach((to, from, next) => {
	const authRequired = !publicRouteNames.includes(to.name);
	const loggedIn = localStorage.getItem("token");
	if (authRequired && !loggedIn) {
		return next({name: "login"});
	} else if (to.name === "login" && loggedIn) {
		return next("inicio");
	} else {
		if(canAccess(to.meta.requiredPermissions)) {
      next()
    }
    else if (to.meta.redirects){
      const newRouteName = haveRedirectForPermissions(to.meta.redirects);
      if(newRouteName) {
        return next({name: newRouteName});
      }
      else {
        next("inicio");
      }
    }
    else {
      next("inicio");
    }
	}
});
export default router;
