const breadcrumb = {
	lote: { text: "Lotes",href: "lotes", color: "" },
	  crearLote: { text: "Crear lotes", href: "crearlote", color: "" },
	  lotes: { text: "Lotes", to: { name: "lotes" }, color: "" },
	  asignarLotes: { text: "Asignar lotes", href: "asignarlotes", color: "" },
  };

const routes = [
	{
		path: "/lotes",
		name: "lotes",
		component: () => import("@/views/Batch/AllLotsView.vue"),
		meta: {
			showSuccessCreated: false,
			transparentCard: true,
			breadcrumbSeparate: true,
			breadcrumb: [breadcrumb.lotes],
			requiredPermissions: ["ver_lotes"]
		},
	},
	{
		path: "/lotes/crear",
		name: "crearlotes",
		component: () => import("@/views/Batch/CreateLotesView.vue"),
		meta: {
			transparentCard: true,
			breadcrumbSeparate: true,
			showSidebarUsers: true,
			showSuccessCreated: false,
			breadcrumb: [breadcrumb.lote, breadcrumb.crearLote],
			requiredPermissions: ["crear_lote"]
		},
	},
	{
		path: "/lotes/asignar",
		name: "asignarlotes",
		component: () => import("@/views/Batch/AssignLotsView.vue"),
		meta: {
			transparentCard: true,
			breadcrumbSeparate: true,
			breadcrumb: [breadcrumb.lotes, breadcrumb.asignarLotes],
			requiredPermissions: ["editar_lote", "reasignar_lote"]
		},
	},
];
export default routes;
