import typificationService from "@/services/TypificationService";

let auxSeccion = {
    nombre: "",
    active: true,
    orden: 0,
    tipo_venta_id: 0,
    tipo_visado_id: 0,
    disabled: true,
    campos: [],
};
let auxCampo = {
    nombre: "",
    orden: 0,
    campo_visado: "",
    seccion_id: 0,
    disabled: true,
    motivos_rechazo: [],
};
let auxMotivo = {
    nombre: "",
    solucion: "",
    cambiar_codigo: false,
    campo_id: 0,
    estado_id: 0,
    instancia_id: 0,
    agrupacion_id: 0,
    disabled: true,
    responsables: [],
};

let auxResponsable = {
    responsable: "",
    enviar_email: false,
    cambiar_codigo: false,
    motivo_rechazo_id: 0,
    canal_id: 0,
    disabled: true,
};

export default {
    state: () => ({
        typification: [],
        tipoVisados: [],
        instancias: [],
        estadoContratos: [],
        agrupaciones: [],
        canales: [],
        empresasDonadoras: [],
        columnasVisado: [],
        tipoVenta: "",

        secciones: [],

        seccion: { ...auxSeccion },
        campo: { ...auxCampo },

        motivo: { ...auxMotivo },
        responsable: { ...auxResponsable },

        selecciones: {
            seccion: "",
            campo: "",
            motivo: "",
            motivoAux: [],
        },
        typificationLoadingData: false,
        typificationLoadingSave: false,
        oldSeccionOrder: null,
        oldFieldOrder:null,
    }),
    mutations: {
        setSeccion(state, index) {
            if (!index && index !== 0) index = state.selecciones.seccion;
            if (index || index === 0) {
                state.seccion = state.secciones[index];
                state.seccion.active = state.seccion.deleted_at ? false : true;
                state.oldSeccionOrder = state.seccion.orden
                if (!state.seccion) {
                    this.commit('resetSeccion');
                }
                return;
            }
            state.seccion = { ...state.seccion };
            state.seccion["disabled"] = true;
            state.seccion.tipo_venta_id = state.tipoVenta.id;

        },
        setCampo(state, index) {
            if (!index && index !== 0) index = state.selecciones.campo;
            if (index || index === 0) {
                state.campo = state.seccion.campos[index];
                state.oldFieldOrder = state.campo.orden

                if (!state.campo) {
                    this.commit('resetCampo');
                }
                state.selecciones.motivoAux[index] = true;
                return;
            }
            state.campo = { ...state.campo };
            state.campo["disabled"] = true;
            state.campo.seccion_id = state.seccion.id;
        },
        setMotivo(state) {
            let index = state.selecciones.motivo;
            if (index || index === 0) {
                state.motivo = state.campo.motivos_rechazo[index];
                if (!state.motivo) {
                    this.commit('resetMotivo');
                    return;
                }
                state.responsable.motivo_rechazo_id = state.motivo.id;
                return;
            }
            state.motivo = { ...state.motivo };
            state.motivo["disabled"] = true;
            state.motivo.campo_id = state.campo.id;
            state.responsable.motivo_rechazo_id = 0;
        },
        setResponsable(state, responsable) {
            state.responsable = responsable;
            if (!state.responsable) {
                state.responsable = { ...auxResponsable };
            }
        },

        resetSeccion(state) {
            state.seccion = { ...auxSeccion };
            state.selecciones.seccion = "";
            state.seccion.tipo_venta_id = state.tipoVenta.id;
        },
        resetCampo(state) {
            state.campo = { ...auxCampo };
            state.selecciones.campo = "";
            state.campo.seccion_id = state.seccion.id;

            state.selecciones.motivoAux = [];
        },
        resetMotivo(state) {
            state.motivo = { ...auxMotivo };
            state.selecciones.motivo = "";
            state.motivo.campo_id = state.campo.id;
        },
    },
    getters: {},
    actions: {
        /**
         * Invoca a la función getTipoVisados del servicio typificationService
         * actualiza el campo tipoVisados del store con el resultado
         **/
        async getTipoVisados({ state, dispatch }) {
            try {
                const response = await typificationService.getTipoVisados();
                state.tipoVisados = response.data.data;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función getEstadoContratos del servicio typificationService
         * actualiza el campo estadoContratos del store con el resultado
         **/
        async getEstadoContrato({ state, dispatch }) {
            try {
                const response = await typificationService.getEstadoContratos();
                state.estadoContratos = response.data.data;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función getColumnasVisado del servicio typificationService
         * informando el ID del tipo de venta
         * actualiza el campo columnasVisado del store con el resultado
         **/
        async getColumnasVisado({ state, dispatch }, tipo_venta_id) {
            try {
                const response = await typificationService.getColumnasVisado(tipo_venta_id);
                let referencia_aux = [];
                response.data.forEach(referencia => {
                    const list_ref = referencia.campo_visado.split('_')
                    const params = {
                        real_name: referencia.campo_visado,
                        show_name: list_ref.join(' '),
                        section: referencia.seccion
                    }
                    referencia_aux.push(params)
                });
                state.columnasVisado = referencia_aux;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a las funciones 
         * - getInstancias del servicio typificationService
         *   - actualiza el campo instancias del store con el resultado
         * - getEstadoContratos del servicio typificationService
         *   - actualiza el campo estadoContratos del store con el resultado
         * - getAgrupaciones del servicio typificationService
         *   - actualiza el campo agrupaciones del store con el resultado
         **/
        async getStaticDataMotivos({ state, dispatch }) {
            typificationService
                .getInstancias()
                .then((r) => {
                    state.instancias = r.data.data;
                })
                .catch((error) => {
                    dispatch("printErrors", error);
                });
            typificationService
                .getEstadoContratos()
                .then((r) => {
                    state.estadoContratos = r.data.data;
                })
                .catch((error) => {
                    dispatch("printErrors", error);
                });
            typificationService
                .getAgrupaciones()
                .then((r) => {
                    state.agrupaciones = r.data.data;
                })
                .catch((error) => {
                    dispatch("printErrors", error);
                });
        },
        /**
         * Invoca a la función getCanales del servicio typificationService
         * actualiza el campo canales del store con el resultado
         **/
        async getCanales({ state, dispatch }) {
            try {
                const response = await typificationService.getCanales();
                state.canales = response.data.data;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función getEmpresasDonadoras del servicio typificationService
         * actualiza el campo empresasDonadoras del store con el resultado
         **/
        async getEmpresasDonadoras({ state, dispatch }) {
            try {
                const response = await typificationService.getEmpresasDonadoras();
                state.empresasDonadoras = response.data.data;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función getSecciones del servicio typificationService
         * actualiza el campo typification del store con el resultado
         **/
        async getTypifications({ state, dispatch }, filters) {
            try {
                state.typificationLoadingData = true;
                const response = await typificationService.getSecciones(filters);
                state.typification = response.data.data;
                state.typificationLoadingData = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función getSeccionesByTipoVentaId del servicio typificationService
         * informando el ID de tipo de venta
         * actualiza los campos secciones y tipoVenta del store con el resultado
         **/
        async getTypificationsByTipoVentaId({ state, dispatch }, tipoVentaId) {
            try {
                state.typificationLoadingData = true;
                const response = await typificationService.getSeccionesByTipoVentaId(tipoVentaId);
                state.secciones = response.data.data;
                state.tipoVenta = response.data.tipo_venta;
                state.typificationLoadingData = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función crearSeccion del servicio typificationService
         * actualiza el campo typificationLoadingSave del store con el resultado
         **/
        async createSeccionDialog({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                await typificationService.crearSeccion(state.seccion);
                dispatch("getTypifications");
                commit("setSuccess", "Se ha creado la sección correctamente.");
                state.typificationLoadingSave = false;

            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función crearSeccion del servicio typificationService
         * actualiza el campo selecciones.seccion del store con el resultado
         **/
        async createSeccion({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                const response = await typificationService.crearSeccion(state.seccion);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                state.selecciones.seccion = response.data.seccion.orden - 1;
                commit("setSeccion");
                commit("setSuccess", "Se ha creado la sección correctamente.");

                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función crearCampo del servicio typificationService
         * actualiza el campo selecciones.campo del store con el resultado
         **/
        async createCampo({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                const response = await typificationService.crearCampo(state.campo);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                commit("setSeccion");
                state.selecciones.campo = response.data.campo.orden - 1;
                commit("setCampo");
                commit("setSuccess", "Se ha creado el campo correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función crearMotivo del servicio typificationService
         * actualiza el campo selecciones.motivo del store con el resultado
         **/
        async createMotivo({ state, commit, dispatch }) {
            try {

                state.typificationLoadingSave = true;
                await typificationService.crearMotivo(state.motivo);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                commit("setSeccion");
                commit("setCampo");
                state.selecciones.motivo = state.campo.motivos_rechazo.length - 1;
                commit("setMotivo");

                commit("setSuccess", "Se ha creado el motivo de rechazo correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);

            }
        },
        /**
         * Invoca a la función crearResponsable del servicio typificationService
         * actualiza el campo responsable del store con el resultado
         **/
        async createResponsable({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                await typificationService.crearResponsable(state.responsable);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                commit("setSeccion");
                commit("setCampo");
                commit("setMotivo");
                state.responsable = { ...auxResponsable };

                commit("setSuccess", "Se ha creado el responsable correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función editarSeccion del servicio typificationService
         * actualiza el campo selecciones.seccion del store con el resultado
         **/
        async editSeccion({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                const response = await typificationService.editarSeccion(state.seccion);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                state.selecciones.seccion = response.data.seccion.orden - 1;
                commit("setSeccion");
                commit("setSuccess", "Se ha editado la sección correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función editarCampo del servicio typificationService
         * actualiza el campo selecciones.campo del store con el resultado
         **/
        async editCampo({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                const response = await typificationService.editarCampo(state.campo);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                commit("setSeccion");
                state.selecciones.campo = response.data.campo.orden - 1;
                commit("setCampo");

                commit("setSuccess", "Se ha editado el campo correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función editarMotivo del servicio typificationService
         **/
        async editMotivo({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                await typificationService.editarMotivo(state.motivo);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                commit("setSeccion");
                commit("setCampo");
                commit("setMotivo");

                commit("setSuccess", "Se ha editado el motivo de rechazo correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función editarResponsable del servicio typificationService
         * actualiza el campo responsable del store con el resultado
         **/
        async editResponsable({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                await typificationService.editarResponsable(state.responsable);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                commit("setSeccion");
                commit("setCampo");
                commit("setMotivo");
                state.responsable = { ...auxResponsable };

                commit("setSuccess", "Se ha creado el responsable correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función borrarSeccion del servicio typificationService
         * actualiza el campo selecciones.seccion del store con el resultado
         **/
        async deleteSeccion({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                await typificationService.borrarSeccion(state.seccion.id);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                if (state.selecciones.seccion !== 0) state.selecciones.seccion -= 1;
                commit("setSeccion");
                if (state.seccion.id) {
                    await dispatch("editSeccion");
                }
                commit("setSuccess", "Se ha desactivado la sección correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función borrarCampo del servicio typificationService
         * actualiza el campo selecciones.campo del store con el resultado
         **/
        async deleteCampo({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;

                await typificationService.borrarCampo(state.campo.id);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                if (state.selecciones.campo !== 0) state.selecciones.campo -= 1;
                commit("setSeccion");
                commit("setCampo");
                if (state.campo.id) {
                    await dispatch("editCampo");
                }

                commit("setSuccess", "Se ha desactivado el campo correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función borrarMotivo del servicio typificationService
         * actualiza los campos motivo y selecciones.motivo del store con el resultado
         **/
        async deleteMotivo({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                await typificationService.borrarMotivo(state.motivo.id);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                commit("setSeccion", state.seccion.orden - 1);
                commit("setCampo");
                state.selecciones.motivo -= 1;
                commit("setMotivo");
                if (!state.motivo) {
                    state.motivo = { ...auxMotivo };
                }

                commit("setSuccess", "Se ha desactivado el motivo de rechazo correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
        /**
         * Invoca a la función borrarResponsable del servicio typificationService
         * actualiza el campo responsable del store con el resultado
         **/
        async deleteResponsable({ state, commit, dispatch }) {
            try {
                state.typificationLoadingSave = true;
                await typificationService.borrarResponsable(state.responsable.id);
                await dispatch("getTypificationsByTipoVentaId", state.tipoVenta.id);

                commit("setSeccion");
                commit("setCampo");
                state.responsable = { ...auxResponsable };
                commit("setMotivo");

                commit("setSuccess", "Se ha desactivado el responsable correctamente.");
                state.typificationLoadingSave = false;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
    },
};
