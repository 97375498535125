const breadcrumb = {
	perfil: { text: "Perfiles", to: { name: "perfiles" }, color: "" },
	crearPerfil: { text: "Crear perfil", to: { name: "crearperfil" }, color: "" },
	editarPerfil: { text: "Editar perfil", to: { name: "editarperfil" }, color: "" },
	verPerfil: { text: "Ver perfil", to: { name: "verperfil" }, color: "" },
};

const routes = [
	{
		path: "/perfiles",
		name: "perfiles",
		component: () => import("@/views/Profile/AllProfilesView.vue"),
		meta: {
			showSidebarProfiles: true,
			transparentCard: true,
			breadcrumbSeparate: true,
			showSuccessCreated: false,
			breadcrumb: [breadcrumb.perfil],
			requiredPermissions: ["ver_perfiles"]
		},
	},
	{
		path: "/perfil/crear",
		name: "crearperfil",
		component: () => import("@/views/Profile/CreateProfileView.vue"),
		meta: {
			breadcrumb: [breadcrumb.perfil, breadcrumb.crearPerfil],
			requiredPermissions: ["crear_perfil"]
		},
	},
	{
		path: "/perfil/:id/editar",
		name: "editarperfil",
		component: () => import("@/views/Profile/CreateProfileView.vue"),
		meta: {
			breadcrumb: [breadcrumb.perfil, breadcrumb.editarPerfil],
			requiredPermissions: ["editar_perfil"]
		},
	},
	{
		path: "/perfil/:id/ver",
		name: "verperfil",
		component: () => import("@/views/Profile/CreateProfileView.vue"),
		meta: {
			breadcrumb: [breadcrumb.perfil, breadcrumb.verPerfil],
			requiredPermissions: ["ver_perfiles"]
		},
	},
];
export default routes;
