import axios from "axios"
import { authHeader } from "./helpers/auth-header";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL || "http://localhost:8000/api/",
})
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
instance.interceptors.request.use(config => {
    config.headers = {
        ...config.headers,
        ...authHeader(),
    };
    return config;
})
//instance.defaults.withCredentials = true; //

export default instance
