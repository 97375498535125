import pozoService from "@/services/PozoService";

export default {
  state: () => ({
    assigned_contracts: 0,
    visado_contracts: 0,
    showComponentReassign:false,
  }),
  mutations: {
    setAssignedContracts(state, contract) {
      state.assigned_contracts = contract;
    },
    setVisadoContracts(state, contract) {
      state.visado_contracts = contract;
    },
    setshowReassign(state, value) {
      state.showComponentReassign = value;
    },
  },
  getters: {},
  actions: {
  },
};
