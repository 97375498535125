const breadcrumb = {
    repositorio: { text: "Ventas Remotas", to: { name: "repositorios" }, color: "" },
    crearRepositorio: {
        text: "Crear Venta Remota",
        href: "crearrepositorio",
        color: "",
    },
    verRepositorio: {
        text: "Ver Venta Remota",
        href: "verrespositorio",
        color: "",
    },
    editarRepositorio: {
        text: "Editar Venta Remota",
        href: "editarrespositorio",
        color: "",
    },
};

const routes = [
    {
        path: "/ventas_remotas",
        name: "repositorios",
        component: () => import("@/views/Repository/RepositoryView.vue"),
        meta: {
            showSideRepositoryComponent: true,
            breadcrumbSeparate: true,
			showSidebarTypificaciones: true,
            breadcrumb: [breadcrumb.repositorio],
            requiredPermissions: ["ver_repositorios"]
        },
    },
    {
        path: "/ventas_remotas/crear",
        name: "crearrepositorio",
        component: () => import("@/views/Repository/CreateRepository.vue"),
        meta: {
            breadcrumb: [breadcrumb.repositorio, breadcrumb.crearRepositorio ],
            requiredPermissions: ["subir_documentos"]
        },
    },
    {
        path: "/venta_remota/:id/ver",
        name: "verrepositorio",
        component: () => import("@/views/Repository/CreateRepository.vue"),
        meta: {
            breadcrumb: [breadcrumb.repositorio, breadcrumb.verRepositorio],
            requiredPermissions: ["ver_repositorios"],
            showSideRepositoryComponent: false

        },
    },
    {
        path: "/venta_remota/:id/editar",
        name: "editarrepositorio",
        component: () => import("@/views/Repository/CreateRepository.vue"),
        meta: {
            breadcrumb: [breadcrumb.repositorio, breadcrumb.editarRepositorio],
            requiredPermissions: ["subir_documentos"]
        },
    },
];
export default routes;
