export default {
    //Retorna valor nombre de usuario guardado en 'userInfo' -> state.js 
    getUserFirstname(state) {
        return state.userInfo.nombres.split(" ")[0];
    },

    formatNames: (state) => (name) => {
        let new_name = name.split(' ')
        new_name = new_name[0]
        new_name = new_name[0].toUpperCase() + new_name.toLowerCase().slice(1)
        return new_name
    },

    // Obtiene el numero de lotes asignados a un usuario guardado en 'usersCreatorsSelected' -> state.js
    getAmountLots: (state) => (id) => {
        const userSelected = state.usersCreatorsSelected.find((user) => {
            return user.id == id;
        });
        let amount = 0;
        for (let lotsNumber in userSelected) {
            if (userSelected[lotsNumber].assigned) {
                amount += userSelected[lotsNumber].assigned
            }
        }
        return amount;
    },
    // Obtiene el numero de contratos asignados a un usuario guardado en 'usersCreatorsSelected' -> state.js
    getAmountContracts: (state) => (id) => {
        const userSelected = state.usersCreatorsSelected.find((user) => {
            return user.id == id;
        });
        let amount = 0;
        for (let lotsNumber in userSelected) {
            if (userSelected[lotsNumber].batches) {
                for (let batch of userSelected[lotsNumber].batches) {
                    amount += batch.cantidad_contratos;
                }
            }
        }
        return amount;
    },
    // Obtiene el numero de contratos asignados previos al guardado en 'usersCreatorsSelected' -> state.js
    getAmountContractsForKey: (state) => (id, key) => {
        const userSelected = state.usersCreatorsSelected.find((user) => {
            return user.id == id;
        });
        let amount = 0;
        if (userSelected[key]) {
            for (let batch of userSelected[key].batches) {
                amount += batch.cantidad_contratos;
            }
        }
        return amount;
    },

    // Obtiene atributos para generar card de una combinacion de contratos para generar lotes
    getCardAttributes: (state) => (codigo) => {
        let channels = [];
        let color = "";
        // let canalTeleventa = "Canal televenta";
        // let canalFijo = "Canal fijo";
        let ventaMovil = "Venta movil";
        let ventaFija = "Venta fijo";
        let b2b = "B2B"
        let b2c = "B2C"
        let sp = " (SP)";
        let p = " (P)";

        if (codigo.includes("TF")) {
            color = "yellowBst";
            channels.push(ventaFija);
        } else if (codigo.includes("F")) {
            color = "greenBst";
            channels.push(ventaFija);
        }
        if (codigo.includes("TM")) {
            color = "blueBst";
            channels.push(ventaMovil);
        } else if (codigo.includes("M")) {
            color = "orangeBst";
            channels.push(ventaMovil);
        }
        if (codigo.includes("B2B")) {
            if (codigo.includes("SP")) {
                channels.push(b2b + sp);
            }
            else {
                channels.push(b2b + p);
            }
        } else if (codigo.includes("B2C")) {
            if (codigo.includes("SP")) {
                channels.push(b2c + sp);
            }
            else {
                channels.push(b2c + p);
            }
        }
        let colorLight = color + "Light";
        return {
            channels,
            color,
            colorLight,
        };
    },
    // Obtiene total de combinacion de lotes que aún no han sido asginados
    getAmountAssignedLots(state, getters) {
        let count = 0;
        for (let user of state.usersCreatorsSelected) {
            count += getters.getAmountLots(user.id);
        }
        return count;
    },
    // Define modo de acción de edición 
    isEditMode(state) {
        return state.mode === 'edit';
    },

    // Define modo de acción de visualizacion 
    isViewMode(state) {
        return state.mode === 'view';
    },

    // Define modo de acción de creacion 
    isCreateMode(state) {
        return state.mode === 'create';
    },

    // Se obtiene un verdadero o falso para verificar si el usuario tiene o no un perfil determinado
    userHasProfile: (state) => (...profileIds) => {
        if (state.userInfo && state.userInfo.profiles) {
            return state.userInfo.profiles.some(profile => profileIds.includes(profile.id));
        }
        return false;
    },

    // Se obtiene un verdadero o falso para verificar si el usuario tiene o no un permiso determinado
    userHasPermission: (state) => (...permissionTags) => {
        if (state.userInfo && state.userInfo.profiles.find(p => p.id === 1)) {
            return true;
        }
        if (state.userInfo && state.userInfo.permissions) {
            return state.userInfo.permissions.some(permission => permissionTags.includes(permission.etiqueta));
        }
        return false;
    }
};
