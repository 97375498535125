import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import state from "./state";
import mutations from "./mutations";
import getters from "./getters";

import typificationModule from "../store/TypificationModule";
import staticModule from "../store/StaticModule";
import visaModule from "../store/VisaModule";
import pozoModule from "../store/PozoModule";
import ticketModule from "../store/TicketModule";
import RepositoryModule from "../store/RepositoryModule";

Vue.use(Vuex);

export default new Vuex.Store({
	state,
	mutations,
	actions,
	getters,
	modules: {
		typificationModule,
		staticModule,
		visaModule,
		pozoModule,
		ticketModule,
		RepositoryModule,
	},
});
