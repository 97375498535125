import staticService from "@/services/StaticService";

export default {
    state: () => ({
        saleTypes: [],
    }),
    mutations: {},
    getters: {},
    actions: {
        /** Descripcion: Obtiene tipos de venta desde servicio 'getSaleTypes' ubicado en carpeta services -> StaticService.js
       * Input:
       * Return: setea valor de 'saleTypes' guardado en state.js
       **/
        async getSaleTypes({ state, dispatch }) {
            try {
                const response = await staticService.getSaleTypes();
                state.saleTypes = response.data.saleTypes;
            } catch (error) {
                dispatch("printErrors", error);
            }
        },
    },
};
