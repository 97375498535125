/** Descripcion: Define variables para los Header de las tablas de Visado Lotes (visar si)
 **/
export const ANALYST_HEADERS = {
  FBAB2C: {
    present: false,
    header: {text: "Venta Fija BA B2C", value: 'FBAB2C', class: "greenBstLight2", cellClass: "greenBstLight",}
  },
  FBAB2B: {
    present: false,
    header: {text: "Venta Fija BA B2B", value: 'FBAB2B', class: "greenBstLight2", cellClass: "greenBstLight"}
  },
  TFBAB2C: {
    present: false,
    header: {text: "Televenta Fijo BA B2C", value: 'TFBAB2C', class: "yellowBstLight2", cellClass: "yellowBstLight"}
  },
  TFBAB2B: {
    present: false,
    header: {text: "Televenta Fijo BA B2B", value: 'TFBAB2B', class: "yellowBstLight2", cellClass: "yellowBstLight"}
  },
  TMBAB2C: {
    present: false,
    header: {text: "Televenta Móvil BA B2C", value: 'TMBAB2C', class: "blueBstLight2", cellClass: "blueBstLight"}
  },
  TMBAB2B: {
    present: false,
    header: {text: "Televenta Móvil BA B2B", value: 'TMBAB2B', class: "blueBstLight2", cellClass: "blueBstLight"}
  },
  MPBAB2C: {
    present: false,
    header: {text: "Venta Móvil BA B2C (P)", value: 'MPBAB2C', class: "orangeBstLight2", cellClass: "orangeBstLight"}
  },
  MPBAB2B: {
    present: false,
    header: {text: "Venta Móvil BA B2B (P)", value: 'MPBAB2B', class: "orangeBstLight2", cellClass: "orangeBstLight"}
  },
  MSPBAB2C: {
    present: false,
    header: {text: "Venta Móvil BA B2C (SP)", value: 'MSPBAB2C', class: "orangeBstLight2", cellClass: "orangeBstLight"}
  },
  MSPBAB2B: {
    present: false,
    header: {text: "Venta Móvil BA B2B (SP)", value: 'MSPBAB2B', class: "orangeBstLight2", cellClass: "orangeBstLight"}
  },
  FBOB2C: {
    present: false,
    header: {text: "Venta Fija BO B2C", value: 'FBOB2C', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  FBOB2B: {
    present: false,
    header: {text: "Venta Fija BO B2B", value: 'FBOB2B', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  TFBOB2C: {
    present: false,
    header: {text: "Televenta Fijo BO B2C", value: 'TFBOB2C', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  TFBOB2B: {
    present: false,
    header: {text: "Televenta Fijo BO B2B", value: 'TFBOB2B', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  TMBOB2C: {
    present: false,
    header: {text: "Televenta Móvil BO B2C", value: 'TMBOB2C', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  TMBOB2B: {
    present: false,
    header: {text: "Televenta Móvil BO B2B", value: 'TMBOB2B', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  MPBOB2C: {
    present: false,
    header: {text: "Venta Móvil BO B2C (P)", value: 'MPBOB2C', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  MPBOB2B: {
    present: false,
    header: {text: "Venta Móvil BO B2B (P)", value: 'MPBOB2B', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  MSPBOB2C: {
    present: false,
    header: {text: "Venta Móvil BO B2C (SP)", value: 'MSPBOB2C', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
  MSPBOB2B: {
    present: false,
    header: {text: "Venta Móvil BO B2B (SP)", value: 'MSPBOB2B', class: "violetBstLight2", cellClass: "violetBstLight"}
  },
}

/** Descripcion: Función "formatNames" para formatear nombres
 * Input:
 * name: String - Nombre
 * Return: String - Nombre con formato en mayúscula primer caracter y el resto en minúsculas
 **/
export function formatNames(name) {
  let new_name = name.split(' ')
  new_name = new_name[0]
  new_name = new_name[0].toUpperCase() + new_name.toLowerCase().slice(1)
  return new_name
}

/** Descripcion: Función "setAnalystCounts" define objeto para el conteo de contratos asignados a un analista inicializado en 0
 * Input:
 * id: Number - Id del Analista
 * name: String - Nombre del analista
 * familyName: String - Apellido del analista
 * Return: Object - Datos del analista y los contratos por tipo de venta asignados
 * **/
export const setAnalystCounts = (id, name, familyName) => {
  return {
    analyst_id: id,
    // analyst: `${name} ${familyName}`,
    analyst: formatNames(name) + " " + formatNames(familyName),
    FBAB2C: 0,
    FBAB2B: 0,
    FBOB2C: 0,
    FBOB2B: 0,
    TFBAB2C: 0,
    TFBAB2B: 0,
    TFBOB2C: 0,
    TFBOB2B: 0,
    MPBAB2C: 0,
    MPBAB2B: 0,
    MPBOB2C: 0,
    MPBOB2B: 0,
    MSPBAB2C: 0,
    MSPBAB2B: 0,
    MSPBOB2C: 0,
    MSPBOB2B: 0,
    TMBAB2C: 0,
    TMBAB2B: 0,
    TMBOB2C: 0,
    TMBOB2B: 0,
    batch: 0,
    contracts: 0
  }
}

/** Descripcion: Define variables de campos extras que reflejan nombre de base de datos en las Secciones de visación
 **/
export const optionalVisaFields = [
  'es_extranjero',
  'es_cliente_vtr',
  'es_documentacion_alas',
  'empresa_donadora_id',
  'es_documentacion_andes'
];

/** Descripcion: Función "formatService" para definir tipo de servicio contratado
 * Input:
 * service: String - Tipo de servicio
 * Return: String - Tipo de servicio mas detallado
 * **/
export const formatService = (service) => {
  if (service.includes("MEGA")) {
    return { title: "Banda Ancha", field: service }
  }
  if (service.includes("TV")) {
    return { title: "Televisión Digital", field: service }
  }
  if (service.includes("MOVIL")) {
    return { title: "Telefonía", field: service }
  }
  return {}
}

/** Descripcion: Función "formatServices" crea una lista de servicios
 * Input:
 * products: Object - Atributos de un producto
 * Return: List/Array - retorna una lista de servicios 
 * **/

export const formatServices = (products) => {
  const services = [];
  products.forEach(product => {
    const service = formatService(product.descripcion_producto);
    if(Object.keys(service).length > 0) {
      services.push(service);
    }
  });
  return services;
}
